.customized-datepicker {
  .k-input-inner {
    padding-left: 8px !important;
    padding-right: 0px !important;
    text-align: center !important;
  }

  // .k-button.k-input-button {
  //   display: none !important;
  // }
}

.customized-datepicker-left-aligned {
  .k-input-inner {
    padding-left: 8px !important;
    padding-right: 0px !important;
    text-align: left !important;
  }
}

.custom-date-picker-popup {
  h1 {
    margin: 0 !important;
  }

  button {
    border-radius: .4em;
    transition: background-color 150ms ease-out;
    line-height: 2 !important;
    font-family: 'Inter', sans-serif;
  }

  button:hover:not(:disabled) {
    background-color: rgba(1, 1, 1, 0.1);
  }

  button:disabled {
    pointer-events: none
  }

  button:focus:not(:disabled) {
    background-color: rgba(1, 1, 1, 0.3);
  }

  button:active:not(:disabled) {
    background-color: rgba(1, 1, 1, 0.3);
  }

  button:disabled {
    color: rgba(1, 1, 1, 0.3);
  }

  .selected {
    background-color: #6f67ff !important;
    color: white !important;

    &:hover {
      background-color: #4f48db !important;
    }
  }

  #calendar-today-button {
    color: #6f67ff;

    &:hover {
      background-color: hsla(243, 100%, 70%, 0.238);
    }
  }
}

.custom-timepicker-popup {
  width: 179px !important;
}