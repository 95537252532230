#login-logo {
  display: flex;
  margin-bottom: 2.5rem;
}

#logo-img {
  height: 50px;
  width: 50px;
  margin-right: 10px;

}

#email {

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  border-width: 1px;
  width: 16rem;
}

#password {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  border-width: 1px;
  width: 16rem;
}

#login-button-div {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #212121;
}

#login-content-children {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  border-radius: 0.375rem;
  width: auto;
  height: auto;
}

#login-error-div {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}

#login-error-span {
  width: 100%;
  font-style: italic;
  text-align: center;
  color: #F87171;
}

#login-email-div {
  display: flex;
  margin-bottom: 0.25rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#login-email-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #4B5563;

}

#login-password-div {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#login-password-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #4B5563;
}