/* Tailwind CSS */
@import 'tailwindcss/base';
// @import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Telerik CSS*/
@import './themes/kendo-theme-custom.scss';
@import './themes/normalize.scss';
// @import "@progress/kendo-theme-material/dist/all.css";


/* components */
@import './themes/customized-datepicker.scss';
@import './themes/checkhq.scss';

html,
body {
  width: 100%;
  height: 100% !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
