@import './constants.scss';

//****************************/

.k-splitbar {
  &:focus {
    background-color: rgb(0, 0, 250) !important;
  }
}

.k-link.k-selected {
  color: #EDEDED !important;

  & .k-panelbar-item-text {
    color: rgb(250, 250, 250) !important;
  }
}

.k-panelbar-item-text {
  color: #EDEDED !important;
}

.k-link {
  cursor: pointer !important;
}

.k-link.k-selected {
  // background-color: rgb(172, 188, 204) !important;
  color: #EDEDED !important;
  box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0) !important;

  .k-svg-icon>svg {
    fill: #424242 !important;
  }
}

.k-link.k-selected .k-panelbar-item-text {
  // color: #000 !important;
  color: #EDEDED !important;
}

.selected-menu-item {
  .k-link {
    background-color: rgb(172, 188, 204) !important;
  }

  .k-link.k-selected {
    background-color: rgb(172, 188, 204) !important;
  }
}

// ------------------ Elements for filtering and sorting in grids ---/

.k-svg-i-sort-asc-small svg,
.k-svg-i-sort-desc-small svg {
  fill: rgb(44, 80, 152);
}

// ------------------ Grid Context Menu -------------------------------/
.k-menu-link-text {
  color: $theme_color !important;
  font-family: $font_family;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

// ----------------- Checkbox ------------------------------------/

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #2c5098;
  color: white;
  background-color: #2c5098;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px #2c509821;
}

// DropDownList

.k-combobox,
.k-dropdownlist.k-picker-outline {
  background-color: transparent !important;
}

.k-picker-outline:hover,
.k-picker-outline.k-hover {
  border-color: #424242;
  color: #424242 !important;
  background-color: #424242;
}

.k-picker-outline:focus,
.k-picker-outline.k-focus {
  box-shadow: none !important;
}

.k-input-outline,
.k-picker-outline {
  border-color: #d6d6d6 !important;
  border-width: 2px;
}

.k-input-outline:focus-within,
.k-picker-outline:focus-within {
  // border-color: #2c5098 !important;
  border-color: #00628c !important;
}

.k-input-outline::after,
.k-picker-outline::after {
  border-width: 0;
}

.k-picker-outline:hover,
.k-picker-outline.k-hover {
  // border-color: #2c5098 !important;
  border-color: #00628c !important;
}

.k-input-solid:focus-within,
.k-picker-solid:focus,
.k-picker-solid.k-focus {
  // border-color: #2c5098 !important;
  border-color: #00628c !important;
}

// ----------------- Grid/Table ------------------------------------/

.k-grid,
.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>.k-table-th,
.k-filter-row>td,
.k-filter-row>.k-table-td {
  border-color: #d6d6d6;
  user-select: none;
}

.k-grid {
  overflow: hidden;
  border-width: 2px;
  // background-color: #fafafa;
  background-color: #fff;
}

.k-input-sm,
.k-picker-sm,
.k-input-md,
.k-picker-md {
  font-size: 12px !important;
}

.k-grid-content .k-table-row:last-child>td,
.k-grid-content .k-table-row:last-child>.k-table-td {
  border-bottom-width: 1px;
}

.k-grid .k-table-th>.k-cell-inner>.k-link,
.k-grid td,
.k-grid .k-table-td {
  padding: 0 6px;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.k-grid .k-table-th>.k-cell-inner>.k-link {
  height: 29px;
  align-items: center;
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: #0000000a !important;
}

.k-grid .k-table-row {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6e6 !important;
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: #fff !important;
}

// grid rows border render
.k-grid .k-table {
  border-collapse: collapse;
}

.k-grid-content .k-table-row>.k-table-td,
.k-grid .k-table-th {
  border-inline-start-width: 0;
  padding-inline: 6px;
}

.k-grid-content .k-table-row>td,
.k-grid-content .k-table-row>.k-table-td {
  border-inline-start-width: 0;
  font-size: 12px;
}

.k-grid-content .k-table-row>.k-table-td:not(:last-child),
.k-grid-content .k-table-row>td:not(:last-child),
.k-grid .k-table-th:not(:last-child) {
  border-inline-end-width: 1px;
}

.k-grid .k-table-thead .k-table-row {
  background-color: #fff !important;
}

.k-grid .k-table-thead .k-column-title {
  line-height: 1.2;
  font-size: 12px;
  font-weight: 400;
  color: #424242;
  white-space: pre-wrap !important;
}

.k-grid .k-table>.k-table-row:not(.k-detail-row):hover,
.k-grid .k-table>.k-table-row:not(.k-detail-row).k-hover,
.k-grid .k-table td:focus,
.k-grid .k-table td.k-focus,
.k-grid .k-table .k-table-td:focus,
.k-grid .k-table .k-table-td.k-focus,
.k-grid .k-table .k-table-th:focus,
.k-grid .k-table .k-table-th.k-focus,
.k-grid .k-table .k-master-row>td:focus,
.k-grid .k-table .k-grouping-row>td:focus,
.k-grid .k-table .k-detail-row>td:focus,
.k-grid .k-table .k-group-footer>td:focus,
.k-grid .k-table .k-master-row>.k-table-td:focus,
.k-grid .k-table .k-grouping-row>.k-table-td:focus,
.k-grid .k-table .k-detail-row>.k-table-td:focus,
.k-grid .k-table .k-group-footer>.k-table-td:focus {
  background-color: inherit !important;
}

.k-grid.listing-grid .k-table-tbody .k-table-row {
  cursor: pointer !important;
}

.k-grid.listing-grid .k-table-tbody .k-table-row:hover {
  background-color: #abb9d6 !important;
  color: #424242 !important;
}

.k-grid.listing-grid .k-table-tbody .k-table-row.k-selected {
  background-color: rgba(44, 80, 152, 0.6) !important;
  color: white !important;
}

.k-grid.listing-grid .k-table-tbody .k-table-row.k-selected:hover {
  // background-color: #8096c1 !important;
  color: white !important;
}

.k-grid.listing-grid .k-table-tbody .k-table-row:not(:last-child) .k-table-td {
  border-block-end-color: transparent !important;
}

.k-grid.listing-grid .k-table-tfoot td {
  border-block-end-width: 0 !important;
  border-inline-start-color: transparent !important;
  text-align: left !important;
  height: 35px !important;
  padding: 0 !important;
}

.k-table-tbody .k-table-row>.k-table-td,
.k-table-list .k-table-row,
.k-list .k-list-item {
  font-size: 12px;
  height: 26px;
}

// custom classname stying for row height in grids
.custom-listing-grid-row .k-table-tbody .k-table-row>.k-table-td,
.custom-listing-grid-row .k-table-list .k-table-row,
.custom-listing-grid-row .k-list .k-list-item {
  font-size: 12px;
  height: 26px;
}

.k-table-tbody .k-table-row>.k-table-td {
  text-align: left;
}

.k-table-tbody .k-table-row>.k-table-td.text-right {
  text-align: right;
}

.k-table-tbody .k-table-row>.k-table-td.text-center {
  text-align: center;
}

.k-table-tbody .k-table-row.k-grid-norecords>.k-table-td {
  text-align: center;
  background-color: white !important;
  cursor: default;
}

.k-table-td.k-grid-edit-cell {
  padding-inline: 0 !important;
}

.k-table-td.k-grid-edit-cell .k-input {
  font-size: 13px;
  border: none;
  background: none;
  padding: 0 6px;
}

.k-table-td.k-grid-edit-cell .k-input::after {
  border: none;
}

.k-table-row .k-input {
  width: 100%;
  border-color: transparent !important;
}

.k-table-row .k-input::after {
  border-width: 0;
  margin-bottom: 1px;
}

.k-grid .k-table-th:focus,
.k-grid .k-table-th.k-focus,
.k-grid td:focus,
.k-grid td.k-focus,
.k-grid .k-table-td:focus,
.k-grid .k-table-td.k-focus,
.k-grid .k-master-row>td:focus,
.k-grid .k-master-row>td.k-focus,
.k-grid .k-grouping-row>td:focus,
.k-grid .k-grouping-row>td.k-focus,
.k-grid .k-detail-row>td:focus,
.k-grid .k-detail-row>td.k-focus,
.k-grid .k-group-footer>td:focus,
.k-grid .k-group-footer>td.k-focus,
.k-grid .k-master-row>.k-table-td:focus,
.k-grid .k-master-row>.k-table-td.k-focus,
.k-grid .k-grouping-row>.k-table-td:focus,
.k-grid .k-grouping-row>.k-table-td.k-focus,
.k-grid .k-detail-row>.k-table-td:focus,
.k-grid .k-detail-row>.k-table-td.k-focus,
.k-grid .k-group-footer>.k-table-td:focus,
.k-grid .k-group-footer>.k-table-td.k-focus,
.k-grid .k-grid-pager:focus,
.k-grid .k-grid-pager.k-focus {
  box-shadow: none !important;
}

// ------------------ Grid data template styles -------------------/
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.right-align-text {
  text-align: end !important;
}

.vertical-align-base {
  vertical-align: baseline !important;
  padding-top: 5px !important;
}

.form-window .k-window-titlebar,
.form-modal .k-window-titlebar,
.k-dialog .k-window-titlebar {
  border-radius: 0;
  background-color: #34579c !important;
  color: white;
  padding: 0.5rem !important;
  height: 40px !important;
}

// custom CSS object for message box titlebars
.confirmMessage .k-dialog .k-window-titlebar {
  height: 26px !important;
}

.k-button-solid-primary,
.k-button-solid-primary.k-time-accept {
  background-color: #438da4;
  color: white;
  height: 35px;
  border: none;
}

.k-button-solid-primary,
.k-button-solid-primary.k-time-accept {
  background-color: #438da4;
  color: white;
  height: 35px;
  border: none;

  &:hover {
    background-color: #438da4;
  }
}

.k-button-solid-base {
  background-image: none !important;
}

.k-button-md {
  padding-block: 8px;
  padding-inline: 16px;
  font-size: 14px;
  line-height: 1.4285714286;
}

.k-dialog .k-dialog-actions {
  padding-inline: 33px;
}

.k-button {
  text-transform: uppercase;
  font-weight: 500;
}

/* Context Menu */
.k-menu-popup {
  padding: 8px 0;
}

.k-menu-group-md .k-menu-item {
  font-size: 14px;
}

.k-menu-vertical>.k-menu-item>.k-menu-link {
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #4E5FBB;
  background: white !important;
  box-shadow: none !important;

  &:hover {
    background: rgba(0, 0, 0, 0.04) !important;
  }
}

.custom-radio-button:checked {
  background-color: #2c5098;
  border-color: #2c5098;
  box-shadow: 0 0 0 2px rgba(44, 80, 152, 0.3) !important;
}

.k-table-row.k-grid-norecords {
  display: none !important;
}


.k-window.k-dialog,
.k-window {
  overflow: hidden;
  border-radius: 10px;
  background-color: transparent;

  .k-window-content {
    background-color: white;
    padding: 0 !important;
  }

  .k-window-titlebar {
    background-color: #34579c;
    line-height: 18px;
    border: none;

    .k-dialog-title {
      span {
        height: 20px;
        line-height: inherit;
      }
    }
  }
}

.line-behind-text {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.line-behind-text:before,
.line-behind-text:after {
  flex-grow: 1;
  height: 2px;
  content: '\a0';
  background-color: #ddd;
  position: relative;
  top: 0.5em;
}

.line-behind-text:before {
  margin-right: 10px;
}

.line-behind-text:after {
  margin-left: 10px;
}

.custom-dropdown {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: fit-content;
  height: fit-content;

  & span.placeholder {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 13px;
    color: #D3D3D3;
    line-height: 30px;
    white-space: pre;
    max-width: calc(100% - 36px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .k-button {
    border-inline-start-width: 0px !important;
  }

  .k-button-outline-base:hover,
  .k-button-outline-base.k-hover {
    color: #424242 !important;
  }
}

.event-numeric-textbox {
  .k-input-inner {
    text-align: center;
  }
}

.invalid-input {

  .k-floating-label-container .k-input-solid,
  .k-picker-solid {
    border: 2px solid red !important;
  }

  .k-floating-label-container .k-label {
    color: red !important;
  }

  .k-floating-label-container:focus-within>.k-label {
    color: red !important;
  }
}

.k-editor {
  .k-editor-toolbar {
    button.k-disabled {
      display: none
    }
  }
}