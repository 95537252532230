.container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s ease-out;
}

.show-sidebar {
  grid-template-columns: 300px calc(100% - 300px);
  transition: all 0.5s ease-in;
}

// ------------------  Header styles -------------------/

.main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EFEFEF;
  box-shadow: none;
}

.header-logo {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 0px;
  gap: 12px;

  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 13px;

  img {
    height: 30px;
  }
}

.header-icon {
  color: rgb(250, 250, 250);
}

.header-utils {
  display: flex;
  align-items: center;
  margin-right: 9px;
  font-family: 'Inter' !important;
  gap: 7px;

  h1 {
    margin: 0px;
    font-size: 23px;
    color: #528FCB;
  }

  button {
    background: transparent;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 2px 2px 0px 2px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.12) !important;
    }
  }
}

.top-bar-divider {
  height: 15px;
  width: 1px;
  background-color: #528FCB;
  margin-left: 7px;
  margin-right: 7px;
}

// ------------------  Sidebar styles -------------------/

.sidebar-container {
  display: block;
  position: relative;
  width: 279px;
  height: calc(100vh - 61px);

  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  background-color: #0D2547;
  color: #EDEDED;

  li {
    background-color: #0D2547;
  }
}

.top-sidebar-border {
  width: 279px;
  border-bottom: 1px solid #5F6E8C;
  margin-bottom: 15px;
}

.bottom-sidebar-border {
  height: 1px;
  width: 279px;
  border-bottom: 1px solid #5F6E8C;
  margin-bottom: 6px;
}

.menu-container {
  width: 100% !important;
  border: 0px;
}

.menu-bar {
  font-family: 'Inter';
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  border-top: 0px !important;

  .k-panelbar-item-text {
    font-size: 15px;
    font-weight: 700;
  }

  .k-link {
    margin: 0px 14px;
    background-color: #0D2547 !important;

  }

  &>.k-link {
    padding: 12px 24px !important;
    margin: 0px;
  }

  &:last-child {
    border-bottom: 0px !important;
  }
}

.menu-item {
  display: block;
  position: relative;
  width: 100%;
  padding: 0px 8px;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;

  .k-panelbar-item-text {
    font-weight: 300;
    font-size: 15px;
  }
}

.selected-menu-item {
  .k-link {
    background-color: rgb(172, 188, 204);
  }
}

.disabled-menu-item {
  .k-link {
    pointer-events: none;
    opacity: 0.6;
    filter: grayscale(0.1);
  }
}

.custom-search-popup {
  max-height: 300px !important;

  .k-focus,
  .k-link.k-menu-link:hover {
    background-color: #ABB9D6 !important;
  }

  .k-focus .k-link.k-menu-link {
    box-shadow: none;
  }

  .k-link.k-menu-link {
    padding: 0 !important;

  }
}

.setup-custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #98aec9 !important;
}


// ------------------ NEW Sidebar styles -------------------/
.new-sidebar-container {
  display: grid;
  overflow: hidden;
  width: 242px;
  height: calc(100% - 50px);
}

.new-sidebar-div {
  display: grid;
  overflow: hidden;
}

.sidebar {
  z-index: 1000;
  border: none !important;
}

.ps-menu-button {
  font-size: 13px;
  margin-left: -18px;
  height: 100% !important;
  font-family: Inter, sans-serif;

  .sidebar-menu-icons {
    color: '#528FCB' !important;
  }
 
  .ps-menu-icon {
    margin-right: 5px;
    margin-left: 7px;
  }

  .ps-menu-label {
    margin-bottom: 1px;
    color: #212121;
  }
}

.ps-submenu-content {
  font-size: 14px;
  background-color: #0D2547;

  .ps-menu-button {
    margin-left: -1px;
    height: 30px !important;
    font-weight: normal !important;
  }
}

.ps-submenu-content.ps-open {
  min-width: 280px;
  max-height: calc(100% - 51px);
}

.ps-menu-button:hover {
  background-color: #1B5199 !important;

  & * {
    color: white;
  }
}

.ps-menuitem-root .ps-disabled {
  color: gray;
}

.subsubmenu {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;

  .ps-menu-button {
    margin-left: 9px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 30px !important;
    font-weight: normal !important;

  }
}

.subsubmenuitem {
  margin-left: -15px;
}

// sidebar app logo and text

.sidebar-logo-container {
  display: flex;
  align-items: center;
  height: 45px;
}

.sidebar-logo-divider {
  width: 280px;
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 11px;
}

// sidebar bottom bar
.bottom-sidebar-container {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.bottom-sidebar-div {
  display: flex;
  flex-direction: row;
  border-right: 1px solid #FFFFFF;
  width: 243px;
  font-family: Inter, sans-serif;
}

.bottom-sidebar {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 17px;
  gap: 4px;

  .bottom-sidebar-label-div {
    display: flex;
    font-size: 11px;
    color: white;
    opacity: 80% ;
    opacity: .8;
  }
}

.grid-dropdown {
  @apply flex w-fit relative items-center h-fit;

  & span.placeholder {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    color: #D3D3D3;
    line-height: 30px;
    white-space: pre;
    max-width: calc(100% - 36px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// fixes placeholder ghosting in comboboxes, maybe a global fix???
.company-profile-form,
.locations-form,
.measurement-unit-dialog,
.status-codes-dialog,
.account-dialog,
.paycode-dialog,
.sales-tax-dialog,
.paygroup-dialog,
.user-dialog,
.project-status-types-dialog {
  .placeholder {
    top: 60% !important;
  }
}

.pto-code-dialog {
  .placeholder {
    top: 60% !important;
  }

  .accrual-start {
    .placeholder {
      top: 50% !important;
    }
  }
}

.holiday-dialog {
 .date-field-right {
  .placeholder {
    top: 60% !important;
  }
 }
}

.acct-defaults {
  .sections-wrapper {
    .left-label-wrapper {
      .placeholder {
        top: 60% !important;
      }
    }
  }
}

.combobox-grid-cell {
  font-size: 13px;
  text-align: left;
  width: 100%;
  padding: 0 10px;
  line-height: 4px;
}

.combobox-grid-cell-disabled {
  color: #A6A6A6 !important;
  pointer-events: none;
}


#save-btn {
  height: 35px;
  width: 85px;
}

#cancel-btn {
  height: 35px;
  width: 85px;
}

#delete-btn {
  height: 35px;
  width: 85px;
}

// global styling
.form-section-header-container {
  background-color: white !important;
  
  .form-title {
    font-family: Calibri Light !important;
    color: #00628c !important;
    font-size: 21px !important;
    font-weight: normal !important;
  }
}